import React, { useEffect, useRef, useState } from "react";
import {CardAddress, AddressForm, SelectInline } from "./styles";
import { useGetDataApi } from "../../hooks/useGetDataApi";
import {
  useStateDigitalTransfersContext,
  useDispatchDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { Option } from "@prima-afp/prima-ui-elements/dist/core";
import { TextGray } from "./styles";
import DropdownInput from "../../components/InputSelect/DropdownInput";
import DropdownPrima from "../../components/PrimaInput/DropdownPrima";
import SelectPrima from "../../components/PrimaInput/SelectPrima";

import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import { UpdateAddress } from "../../services";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import arrowUpGray from "../../assets/images/arrowUpGray.svg";
import arrowDownGray from "../../assets/images/arrowDownGray.svg";
import {sendToData} from '../../../digital-transfers/constants/sendToDataLayer';
import Toast from "../../components/Toast/Toast";

export const FrameAddress = () => {
  const { GetVias, GetSectors, GetDepartments, GetProvinces, GetDistricts } =
    useGetDataApi();

  const { userWeb, listGenerals } = useStateDigitalTransfersContext();
  const { SendFetch, SetLoading } = useCatchFetch();

  const {
    codeVia,
    descriptionVia,
    codeHousing,
    descriptionHousing,
    departmentCode,
    provinceCode,
    districtCode,
    descriptionHousingInput,
    descriptionViaInput,
    districtCodeInput,
    provinceCodeInput,
    departmentCodeInput,
  } = userWeb;

  const dispatch = useDispatchDigitalTransfersContext();

  const [listVias, setListVias] = useState([]);
  const [listSectors, setListSectors] = useState([]);
  const [listDepartments, setListDepartments] = useState([]);
  const [listProvinces, setListProvinces] = useState([]);
  const [listDistricts, setListDistricts] = useState([]);
  const [ showSectionAddress, setShowSectionAddress ] = useState(false);
  const [ department, setDepartment] = useState('');
  const [ province, setProvince] = useState('');
  const [ district, setDistrict] = useState('');
  const [ statusForm, setStatusForm] = useState('ACTIVE');
  const [formDisabled, setFormDisabled] = useState(true);
  const [resetKey, setResetKey] = useState(0);

  const first = useRef();

  useEffect(() => {
    if(userWeb.departmentCode == "" || userWeb.provinceCode == "" ||  userWeb.districtCode == "" || userWeb.descriptionVia == "" || statusForm == 'disabled' ){
      setFormDisabled(true);
    }else{
      setFormDisabled(false);
    }
  }, [userWeb, statusForm]);

  const resetToast = () => {
    setResetKey(prevKey => prevKey + 1);
  };

  useEffect(() => {
    const vias = GetVias();
    setListVias(vias);
    if (vias.length > 0) {
      dispatch({
        type: "FIELD_SECOND",
        payload: { field1: "userWeb", field2: "codeVia", value: vias[0].code },
      });
    }
    const sectors = GetVias();
    GetSectors(sectors);
    if (sectors.length > 0) {
      dispatch({
        type: "FIELD_SECOND",
        payload: {
          field1: "userWeb",
          field2: "codeHousing",
          value: sectors[0].code,
        },
      });
    }
    setListSectors(GetSectors());
    setListDepartments(GetDepartments());
  }, [listGenerals]);

  const isDisabled = () => {
    if(userWeb.departmentCode == "" || userWeb.provinceCode == "" ||  userWeb.districtCode == "" || userWeb.descriptionVia == "" || statusForm == 'disabled' ){
      return true;
    }
    return false;
  };

  const Send = (type) => {  
    if(type == 'cancel'){
      var payload = {section: "actualizar direccion", option: "cancelar"};
      sendToData(window.location.href, "button_click", "button_click", payload); 
      setShowSectionAddress(false);
    }
    if(type == 'start'){
      SetLoading(true);
      var sector = listSectors.find((element) => { return element.code === userWeb.codeHousing;});
      var via = listVias.find((element) => { return element.code === userWeb.codeVia;});
      const body= {};
      body.documentType = userWeb.documentType;
      body.documentNumber = userWeb.documentNumber;
      body.departmentCode = userWeb.departmentCode;
      body.provinceCode = userWeb.provinceCode;
      body.districtCode = userWeb.districtCode;
      body.codeVia = userWeb.codeVia;
      body.codeViaValue = via.description;
      body.descriptionVia = userWeb.descriptionVia;
      body.codeHousing = '';
      body.codeHousingValue ='';
      body.descriptionHousing ='';

      var payload1 = {section: "actualizar direccion", option: "guardar", edad: userWeb.age};
      sendToData(window.location.href, "button_click", "button_click", payload1);  
      resetToast();
      SendFetch(UpdateAddress(body))
      .then((res) => {             
        var payload2 = {section: "popup direccion actualizada", option: "direccion actualizada"};
        sendToData(window.location.href, "card_view", "card_view", payload2);  
        setStatusForm('disabled');
        resetToast();
      })
      .catch((e) => {
        console.log(e);
        dispatch({
          type: 'SET_CONFIG_MODAL',
          payload: {
              idModal:  "btn-step2-error-employeer-send",
              content: 'Error al registrar dirección',
          }
        });
      });
    }
  };

  const showSection = () => {
    if(showSectionAddress == false){
      var payload = {section: "finalizacion-solicitud", option: "actualizar direccion de mi domicilio", edad: userWeb.age};
      sendToData(window.location.href, "button_click", "button_click", payload);     
    }
    setShowSectionAddress(!showSectionAddress);
  };

  const onChangeTypeSector = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "codeHousing",
        value: e.code,
      },
    });
  };
  const onChangeSector = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionHousing",
        value: e.target.value,
      },
    });
  };
  const onChangeTypeVia = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "codeVia",
        value: e,
      },
    });
  };
  const onChangeVia = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "descriptionVia",
        value: e.target.value,
      },
    });
  };

  const onChangeDepartment = async (e) =>{
    setDepartment(e);
    dispatch({ type: "FIELD_SECOND", payload: {field1: "userWeb",field2: "departmentCode",value: e}});
    const list = await GetProvinces(e);
    setListProvinces(list.filter(item => item.code !== '00'));
  };

  const onChangeProvince = async (e) =>{
    setProvince(e);
    dispatch({ type: "FIELD_SECOND", payload: {field1: "userWeb",field2: "provinceCode",value: e}});
    const list = await GetDistricts(department, e);
    setListDistricts(list.filter(item => item.code !== '00'));
  };

  const onChangeDistrict = async (e) =>{
    setDistrict(e);
    dispatch({ type: "FIELD_SECOND", payload: {field1: "userWeb",field2: "districtCode",value: e}});
  };



  return (
    <>
          <CardAddress  >
            <div className="divCard-1" onClick={showSection}>
              <div className="divCard-2">
                <div className="divCard-3 green">¿Te ayudamos en algo más?</div>
                <div className="divCard-3">Actualizar la dirección de mi domicilio</div>
              </div>
              <div className="divCard-2">
                {!showSectionAddress && (
                  <img
                    loading="lazy"
                    src={arrowDownGray}
                    className="img"
                  />
                )}
                {showSectionAddress && (
                  <img
                    loading="lazy"
                    src={arrowUpGray}
                    className="img"
                  />
                )}
              </div>
            </div>

            {
            showSectionAddress && 
            <AddressForm ref={first} >
            <div className="title-1"> Ingresa los datos de tu nueva dirección:</div>
    
              <div className="gridInputs">
                  <div className="groupFA groupFrameAddress">
                    <SelectPrima listOption={listDepartments} defaultSelect={''} value={userWeb.departmentCode} 
                    saveSelectOption={onChangeDepartment}
                    status={statusForm}
                    placeholder={'Departamento'}
                    type={'texto'}
                    label={'Departamento'}
                    />
                    <SelectPrima listOption={listProvinces} defaultSelect={''} value={userWeb.provinceCode} 
                    saveSelectOption={onChangeProvince}
                    status={statusForm}
                    placeholder={'Provincia'}
                    type={'texto'}
                    label={'Provincia'}
                    />
                  </div>
                  <div className="groupFA groupFrameAddress">
                    <SelectPrima listOption={listDistricts} defaultSelect={''} value={userWeb.districtCode} 
                    saveSelectOption={onChangeDistrict}
                    status={statusForm}
                    placeholder={'Distrito'}
                    type={'texto'}
                    label={'Distrito'}
                    />
                    <DropdownPrima listOption={listVias} defaultSelect={codeVia} value={userWeb.descriptionVia} 
                    saveSelectOption={onChangeTypeVia}
                    saveValueInput={onChangeVia}
                    status={statusForm}
                    maxLength={500}
                    placeholder={'Direccion'}
                    type={'texto'}
                    label={'Dirección'}
                    />
                  </div>
              </div>
    
              <div className="btnRegister">
                  <Button className={"butFinish cancelButton"} onClick={()=> Send('cancel')}  >Cancelar</Button>
                  <Button className={formDisabled ? "startButton newUiStartButton disabledBtn" : "startButton newUiStartButton enabledBtn"} disabled={isDisabled()} onClick={()=> Send('start')}   >Guardar</Button>
              </div>
    
          </AddressForm>
          }
          {
            statusForm == 'disabled' && <Toast message="Hemos actualizado tu dirección" duration={5000} resetKey={resetKey}/>
          }
          </CardAddress>




    </>
  );
};
