import React from "react";
import { StepTitleGray } from "../../../shared/components/StepTitle";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { DivFramePEP } from "./styles";
import RadioButtonInput from "../../components/PrimaInput/RadioButtonInput";
import SimpleInput from "../../components/PrimaInput/SimpleInput";
import { sendToData } from "modules/digital-transfers/constants/sendToDataLayer";

export const FramePEP = () => {
  const { heldPosition, userWeb } = useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();
  const saveHeldPosition= (e) => {
    sendGTM(e);
    dispatch({
      type: "FIELD",
      payload: { field: "heldPosition", value: e },
    });
  };
  
  const sendGTM = (cargo) => {
    console.log(cargo);
    const textCargo = cargo? 'cargo_si' : 'cargo_no';
    const current = window.location.href;
    var payload = {
      section: "paso 1_1",
      sub_section: "cargo_publico",
      option: `cargo_publico: ${textCargo}`,
      dni: userWeb.documentNumber,
      telefono: userWeb.phoneNumber,
      email: userWeb.email,
      edad: userWeb.age,
    };
    sendToData(current, "step", "button_click", payload, current);
  }

  const onChangeName= (e) =>{
    dispatch({ type: "FIELD_SECOND", payload: { field1: "userWeb", field2: "pepInstitutionName", value: e.target.value,},});
  };
  const onChangeHeld= (e) =>{
    dispatch({ type: "FIELD_SECOND", payload: { field1: "userWeb", field2: "pepPositionHeld", value: e.target.value,},});
  };

  return (
    <DivFramePEP>
      <div style={{ display: "inline" }}>
        <StepTitleGray id="FramePEPid">
        {
            (window.innerWidth < 451) && <p style={{ font: '700 16px / 20px Calibri, -apple-system, Roboto, Helvetica, sans-serif', color: '#4E5258'}}>¿Has tenido un cargo público en el Perú o en el extranjero los últimos 5 años?*</p>
        }
        {
            (window.innerWidth > 451) && <p style={{color:'#4E5258'}}>¿Has desempeñado un cargo público en el Perú o en el extranjero durante los ultimos 5 años?*</p>
        }
        </StepTitleGray>
      </div>

      <div className="wrapperButton" style={{ display: "flex" }}>
        <RadioButtonInput value={heldPosition} saveSelection={saveHeldPosition}/>
      </div>

      {heldPosition && (
        <div id="wrapperInput">
          <SimpleInput inputName="input_institucion" section={'paso 1_2'} data-testid="input-field" saveValueInput={onChangeName} type={'simpleInput'} value={userWeb.pepInstitutionName} label={'Ingresa el nombre de la institución'} placeholder={ (window.innerWidth < 451) ? 'Nombre de la institución':'' } />
          <SimpleInput inputName="cargo" section={'paso 1_2'} data-testid="input-field2" saveValueInput={onChangeHeld} type={'simpleInput'} value={userWeb.pepPositionHeld} label={'Ingresa el cargo desempeñado'} placeholder={ (window.innerWidth < 451) ? 'Cargo desempeñado':''} />
        </div>
      )}

      <div id="disclaimer" className="">
        <p id="disclaimerSpan">
          *Declaro que la información proporcionada se ajusta a la verdad y ha
          sido brindada según lo solicitado por la Resolución SBS N°2660-2015 y
          sus modificatorias, por lo cual asumo total responsabilidad.
        </p>
      </div>
    </DivFramePEP>
  );
};
