import React from "react"
import { OptionsMenu } from "../../constants/data"
import LogoPrima from "../../../App/assets/images/logoPrimaOrange.svg"
import { HeaderCont } from "./Style"
import { redirectURL } from "../../constants/functions"
import { sendToDataLanding } from "../../../digital-transfers/constants/sendToDataLayer"

const HeaderLanding = ({sectionsRef}) => {

  function scrollToSection(option) {
    let sectionId = ""
    let optionDataLayer = ""
    switch (option) {
      case "Cámbiate hoy":
        sectionId = "page1"
        optionDataLayer = "cambiate hoy"
        break
      case "¿Cuánto toma?":
        sectionId = "page2"
        optionDataLayer = "cuanto toma"
        break
      case "Preguntas frecuentes":
        sectionId = "page4"
        optionDataLayer = "preguntas frecuentes"
        break
      default:
        break
    }
    const section = sectionsRef.current[sectionId]
    if (section) {
      const offset = 94 // 70 Ajusta según sea necesario
      // Obtiene la posición actual del elemento
      const rect = section.getBoundingClientRect()
      // Calcula la nueva posición restando el offset
      let newPosition = window.scrollY + rect.top - offset
      const objectDataLayer = {
        event: "menu_click",
        payload: {
          section: "landing traspaso",
          sub_section: "menu",
          option: optionDataLayer
        }        
      }
      sendToDataLanding(objectDataLayer);
    }
  }
  
  return (
    <HeaderCont>
      <div
        className={`headerLanding`}
      >
        <div className="contentHeader">
          <img src={LogoPrima} alt="PRIMA" />
          <div className="btnsHeader">
            {OptionsMenu.map((itemMenu, index) => {
              return (
                <a
                  key={index}
                  onClick={() => scrollToSection(itemMenu.description)}
                >
                  {itemMenu.description}
                </a>
              )
            })}
          </div>
          <button
            id="btn-change2"
            onClick={() => {
              redirectURL("traspaso", {
                event: "menu_click",
                payload: {
                  section: "landing traspaso",
                  sub_section: "boton - menu",
                  option: "cambiate ya"
                }        
              })
            }}
            className="btnHeader"
          >
            Cámbiate ya
          </button>
        </div>
      </div>
      <div className="helpHeader"></div>
    </HeaderCont>
  )
}

export default HeaderLanding
