import React from 'react'
import { ThridBannerContainer } from './styled'
import imgBanner3 from "../../../assetss/image/imgBanner3.svg";
import { redirectURL } from "modules/landing/constants/functions";

const ThirdBanber = () => {
  return (
    <ThridBannerContainer>
      <div className="contents">
        <div className="contentText">
          <h1 className="title">
          Somos la primera AFP en contar con un {" "}
            <p className="textRed">programa de ahorro previsional por consumos</p>
          </h1>
          <div className="btnsChange">
            <button
              className="btnSlide"
              onClick={() =>
                redirectURL("traspaso", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "cambiate ya",
                    position_banner: "banner_3"
                  },
                })
              }
            >
              Cámbiate ya
            </button>
            <button
              className="btnSlideSecundary"
              onClick={() =>
                redirectURL("traspasoAsesor", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "me estan asesorando",
                    position_banner: "banner_3"
                  },
                })
              }
            >
              Me está ayudando un asesor
            </button>
          </div>
        </div>
        <div className="contentImage">
          <img src={imgBanner3} />
        </div>
      </div>
    </ThridBannerContainer>
  )
};

export default ThirdBanber;
