import React from "react";
import { ContentMovileQuery, TimelineItem } from "./StyleMovile";
import x from "../../images/x.svg";
import check from "../../images/Check.svg";
import { sendToData } from "../../../../constants/sendToDataLayer"
import { useHistory } from "react-router-dom";
import { useDispatchDigitalTransfersContext } from "../../../../../digital-transfers/context/DigitalTransfersContext"

export const ResponseMovile = ({ response }) => {
  const history = useHistory();
  const dispatch = useDispatchDigitalTransfersContext();
  const redirectStep = () => {
    sendToData(window.location.href, "button_click", "button_click", {
      section: "Landing consulta",
      option: "comienza tu solicitud",
    });
    dispatch({ type: "INITIAL_STATE" });
    history.push("/pasos0");
  };
  return (
    <ContentMovileQuery>
      {!response.noRequest && (
        <div className="contentResponseQueryM">
          <div className="titleResponseQueryM">Hola {response.name},</div>
          <div className="descriptionResponseQueryM">{response.subTitle}</div>
          <div className="statusResponseQueryM">
            <div className="timeline">
              {response.steps.map((step, index) => (
                <TimelineItem
                  status={step.status}
                  color={step.color}
                  key={index}
                  backGroundColor={step.backgroundColor}
                >
                  <div className="timeline-item">
                    {step.number !== 0 && (
                      <div className="timeline-content">
                        <div className="circleMovil">
                          {step.finished === true ? (
                            <img src={check} alt="aceptada" />
                          ) : step.finished === false ? (
                            <img src={x} alt="rechazada" />
                          ) : (
                            step.number
                          )}
                        </div>
                        <div className="circleDescriptionMovil">
                          {step.description}{" "}
                        </div>
                      </div>
                    )}
                    {step.number === 0 && (
                      <div className="timeline-content">
                        <div className="messageResponseQueryM">
                          <div className="messageQueryM">
                            <h2 className="titleMessageQueryM">
                              {" "}
                              {response.messageTitle}{" "}
                            </h2>
                            <h2 className="contentMessageQueryM">
                              {response.message}
                            </h2>
                          </div>
                          <div className="contentImageQueryM">
                            <img src={response.imageMessage} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </TimelineItem>
              ))}
            </div>
          </div>
        </div>
      )}

      {response.noRequest && (
        <div className="contentResponseQueryMS">
          <div className="messageResponseQueryMS">
            <div className="messageQueryMS">
              <h2 className="contentMessageQueryMS textBold">
                {response.message}
              </h2>
              <div style={{ paddingTop: "15px", display: "flex", justifyContent: "center" }}>
                <a
                  onClick={ redirectStep }
                  className="btnChangeNow"
                >
                  Cámbiate a Prima AFP
                </a>
              </div>
              <div className="contentImageQueryMS">
                <img src={response.imageMessage} />
              </div>
            </div>
          </div>
        </div>
      )}
    </ContentMovileQuery>
  );
};
