import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import SimpleInput from "../../components/PrimaInput/SimpleInput";
import { updateUrl } from "../../constants/updateUrl";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { Abandon } from "../../services";
import { ContainerContactData, ContainerInputs } from "./styles";


export const StepContactData = () => {
  const {
    listGenerals,
    userWeb,
    enabledAbandonmentStep1,
  } = useStateDigitalTransfersContext();
  const {
    errorPhoneInput,
    errorEmailInput,
    errorEmailCinput,
    errorDocNumberInput,
  } = userWeb;
  const dispatch = useDispatchDigitalTransfersContext();

  const [style1, setStyle1] = useState({});
  const [style2, setStyle2] = useState({});
  const [style3, setStyle3] = useState({});
  const [documentResponse, setDocumentResponse] = useState([]);
  const { SendFetch, SetLoading } = useCatchFetch();
  const history = useHistory();  


  useEffect(() => {
    setDocumentResponse(
      listGenerals?.listDocumentsTypes
        ? listGenerals.listDocumentsTypes
        : [{ code: "00", description: "DNI" }]
    );
  }, []);

  useEffect(() => {
    if ( sessionStorage.getItem('timestampFirstQuery') != null && sessionStorage.getItem('timestampFirstQuery') != undefined 
    && sessionStorage.getItem('userWeb') != null && sessionStorage.getItem('userWeb') != undefined){
      SetLoading(true);
      const objetoEncriptado = sessionStorage.getItem("userWeb");

      // Desencriptar el objeto
      const secretKey = sessionStorage.getItem("timestampFirstQuery");
      const bytes = CryptoJS.AES.decrypt(objetoEncriptado, secretKey);
      const payloadContext = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      const currentUrl = window.location.href;
      dispatch({ type: 'FIELD', payload: { field: 'stepCurrent', value: 3 } });
      dispatch({
        type: "UPDATE_CONTEXT",
        payload: payloadContext,
      });
      updateUrl(history, currentUrl, '/pasos3');
    }

  }, []);


  useEffect(() => {
    errorDocNumberInput?
      setStyle1({ border: "2px solid red" })
      : setStyle1({});
    errorDocNumberInput?
      setStyle2({ color: "red !important" })
      : setStyle2({});
    errorDocNumberInput? 
      setStyle3({ borderLeftColor: "red !important" })
      : setStyle3({});
  }, [errorDocNumberInput]);

  const onChangeDoc = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "documentNumber",
        value: e.target.value.replace(/[^0-9]/g, ""),
      },
    });
  };
  const onChangePhoneNumber = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "phoneNumber",
        value: e.target.value.replace(/[^0-9]/g, ""),
      },
    });
  };
  const onChangeMail = (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "email",
        value: e.target.value.replace(/\s/g, ''),
      },
    });
  };
  const onChangeMailConfirmation= (e) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: {
        field1: "userWeb",
        field2: "emailConfirmation",
        value: e.target.value.replace(/\s/g, ''),
      },
    });
  };

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener('beforeunload', handleBeforeUnload);
    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enabledAbandonmentStep1]);

  const handleBeforeUnload = async (e) =>{
      const message = "¿Seguro que quieres salir?";
      e.returnValue = message;
      const userResponse = window.confirm(message);
      try { 
          if(enabledAbandonmentStep1 == true){
            var BodyPayload = { step: 1, documentNumber: userWeb.documentNumber, documentType: "00", active: true };
            var abandonRes = await SendFetch(Abandon(BodyPayload));
          }
          
        } catch (error) {
          console.error(error);
      }
  };

  return (
    <ContainerContactData>
      <ContainerInputs id="idStep1ContainerInputs">
        <div className="disclaimerStep1">
          Ingresa tus datos para empezar tu cambio a Prima AFP:
        </div>
        <div id="idWsrapperInputs" className="wrapperInputs" style={{display:'flex', justifyContent:'space-between', marginBottom:'40px', marginTop:'64px', width:'633px', height: '84px' }}>
            <SimpleInput inputName={'input_dni'} section={'paso 0'} saveValueInput={onChangeDoc} type={'docNumber'} maxLength={8} value={userWeb.documentNumber} label={'Ingresa tu documento de identidad'} placeholder={'N° de documento'} flagError={errorDocNumberInput} errorMessage="El número de DNI no es válido" haveIcon={false} textIcon={'DNI'}/>    
            <SimpleInput inputName={'input_numero'} section={'paso 0'} saveValueInput={onChangePhoneNumber} type={'phone'} maxLength={9} value={userWeb.phoneNumber} label={'Ingresa tu número de celular'} placeholder={'Ejem. 980 998 099'} flagError={errorPhoneInput} errorMessage="El número de celular no es válido" haveIcon={false} textIcon={'+51'} showTooltipInput={false} tooltipMessage={'Coloca correctamente tu número'}/>      
        </div>
        <div className="wrapperInputs" style={{display:'flex', justifyContent:'space-between', marginBottom:'40px', width:'633px', height: '84px' }}>
            <SimpleInput inputName={'input_email'} section={'paso 0'} saveValueInput={onChangeMail} type={'mail'} value={userWeb.email} label={'Ingresa tu correo electrónico'} placeholder={'Ingresa tu correo'} flagError={errorEmailInput} errorMessage="El correo ingresado no es válido" haveIcon={true}/>
            <SimpleInput inputName={'input_confirmar_email'} section={'paso 0'} saveValueInput={onChangeMailConfirmation} type={'mail'} value={userWeb.emailConfirmation} label={'Ingresa tu correo electrónico'} placeholder={'Confirma tu correo'} flagError={errorEmailCinput} errorMessage="El correo electrónico no coincide" haveIcon={true} NoCopyPaste={false}/>
        </div>
      </ContainerInputs>
    </ContainerContactData>
  );

};
