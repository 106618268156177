import { Tooltip } from "@prima-afp/prima-ui-elements/dist/core";
import { useEffect, useState } from "react";
import { StepTitleGray } from "../../../shared/components/StepTitle";
import iIcon from "../../assets/images/infos2.svg";
import Slider from "../../components/Slider/Slider";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { useGetDataApi } from "../../hooks/useGetDataApi";
import { FramePEP } from "./FramePEP";
import { ContarinerStep2, TextRegards } from "./styles";
import PrimaCards from "../../components/PrimaCards/PrimaCards";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { Abandon } from "../../services";
import { sendToData } from "modules/digital-transfers/constants/sendToDataLayer";
import { identical } from "ramda";

export const StepFundType = () => {
  const { userWeb, typesList } = useStateDigitalTransfersContext();
  const { GetListEmployedTypes } = useGetDataApi();
  const dispatch = useDispatchDigitalTransfersContext();
  const fullName = userWeb.firstName;

  const [listEmployedTypes, setListEmployedTypes] = useState([]);
  const { SendFetch } = useCatchFetch();
  const [optionsCard, setOptions] = useState([]);
  const optionCards1 = [
    {
      id: 1,
      type: 1,
      label: "Fondo 1",
      description:
        "Crecimiento con seguridad, perfecto si buscas un balance sin mucho riesgo.",
      strongDescription: "",
    },
    {
      id: 2,
      type: 2,
      label: "Fondo 2",
      description:
        "Crecimiento equilibrado, ideal si eres paciente y quieres más rendimiento.",
      strongDescription: "",
    },
    {
      id: 3,
      type: 3,
      label: "Fondo 3",
      description:
        "Crecimiento alto, riesg alto. Ideal para quienes piensan a largo plazo.",
      strongDescription: "",
    },
  ];
  const optionCards2 = [
    {
      id: 0,
      type: 0,
      label: "Fondo 0",
      description:
        "Seguro y estable, ideal si prefieres evitar riesgos si estás cerca de jubilarte.",
      strongDescription: "",
    },
    {
      id: 1,
      type: 1,
      label: "Fondo 1",
      description:
        "Crecimiento con seguridad, perfecto si buscas un balance sin mucho riesgo.",
      strongDescription: "",
    },
    {
      id: 2,
      type: 2,
      label: "Fondo 2",
      description:
        "Crecimiento equilibrado, ideal si eres paciente y quieres más rendimiento.",
      strongDescription: "",
    },
  ];

  useEffect(() => {
    setListEmployedTypes(GetListEmployedTypes());
    userWeb.age > 59 ? setOptions(optionCards2) : setOptions(optionCards1);
    sessionStorage.removeItem("userWeb");
  }, []);

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = async (e) => {
    const message = "¿Seguro que quieres salir?";
    e.returnValue = message;
    const userResponse = window.confirm(message);
    try {
      var BodyPayload = {
        step: 2,
        documentNumber: userWeb.documentNumber,
        documentType: "00",
        active: true,
      };
      var abandonRes = await SendFetch(Abandon(BodyPayload));
    } catch (error) {
      console.error(error);
    }
  };

  const selectFundType = (item) => {
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "codeFundType", value: item.id },
    }); // item.id
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "fundType", value: item.type },
    }); // item.type
    sendGTM(item);
  };

  const sendGTM = (item) => {
    const current = window.location.href;
    var payload = {
      section: "paso 1_1",
      sub_section: "eligir_tipo_fondo",
      option: `eligir_tipo_fondo: fondo_${item.type}`,
      dni: userWeb.documentNumber,
      telefono: userWeb.phoneNumber,
      email: userWeb.email,
      edad: userWeb.age,
    };
    sendToData(current, "step", "button_click", payload, current);
  }

  return (
    <ContarinerStep2 id="idContarinerStep2">
      <div id="divStep2">
        {fullName != null && fullName != "" && (
          <TextRegards className="regards" style={{ color: "#FF4F00" }}>
            <span>
              Hola,
              <b style={{ fontWeight: "700" }}> {fullName}</b>
            </span>
          </TextRegards>
        )}

        {
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: window.innerWidth > 451 ? "24px" : "0px",
            }}
          >
            <StepTitleGray id="foundTypeP">
              Elige tu tipo de fondo:{" "}
            </StepTitleGray>
            <Tooltip
              id="tooltip"
              theme="light"
              style={{ display: "flex", marginLeft: "3px" }}
              content={
                <div style={{ color: "#696158" }}>
                  Podrás cambiar tu tipo de fondo en cualquier momento.
                </div>
              }
            >
              <img id="tooltipImg" src={iIcon} alt="tooltipPensioner" />
            </Tooltip>
          </div>
        }

        {window.innerWidth < 451 && (
          <Slider
            id="idSlider"
            optionSilder={typesList}
            selectOption={selectFundType}
          />
        )}
        {window.innerWidth > 451 && (
          <PrimaCards
            options={optionsCard}
            initialOption={userWeb.fundType}
            saveCardSelection={selectFundType}
          />
        )}
      </div>

      <FramePEP />
    </ContarinerStep2>
  );
};
