import React from 'react';
import { TypographyStyled } from './styles';

export const Text = (props) => {
    return (
        <TypographyStyled variant="body" {...props}></TypographyStyled>
    );
};


