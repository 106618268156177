import { Button } from "@prima-afp/prima-ui-elements/dist/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TraspasoModal } from "../../../shared/components/Modal";
import { sendToData } from "../../constants/sendToDataLayer";
import { updateUrl } from "../../constants/updateUrl";
import {
  useDispatchDigitalTransfersContext,
  useStateDigitalTransfersContext,
} from "../../context/DigitalTransfersContext";
import { useCatchFetch } from "../../hooks/useCatchFetch";
import { Abandon, SavePhotoReco } from "../../services";
import { ButtonsContainer } from "../StepsRecognition/styles";
import { DivBackButton, Step3Wrapper } from "./styles";

import asistenteVirtual from "../../assets/images/asistenteVirtual.svg";
import imageLike from "../../assets/images/like.svg";
import { helpMail } from "../../services";
import Carousel from "../../components/PrimaCarrusel/PrimaCarrusel";

export const StepRecognition = () => {
  const { biometricVerified, userWeb, DNI } =
    useStateDigitalTransfersContext();
  const dispatch = useDispatchDigitalTransfersContext();

  const { SendFetch, SetLoading } = useCatchFetch();
  const [showModal, setShowModal] = useState(false);
  const [biometricUrl, setBiometricUrl] = useState(
    "/pasos3validacion-biometrica"
  );
  const history = useHistory();
  const [valuesDevice, setValuesDevice] = useState("");
  const [userAgentValue, setUserAgentValue] = useState("");
  const [btnText, setBtnText] = useState("Continuar");
  const [helpCall, setHelpCall] = useState(false);

  useEffect(() => {
    if (!biometricVerified) {
      dispatch({
        type: "FIELD",
        payload: { field: "imageBiometric", value: null },
      });
      setShowModal(true);
      if (window.location.href.includes("asesor")) {
        setBiometricUrl("/asesor/pasos3validacion-biometrica");
      }
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "FIELD",
      payload: { field: "DNI", value: userWeb.documentNumber },
    });
  }, []);
  useEffect(() => {
    localStorage.setItem("DNI", userWeb.documentNumber);
    window.DNISession = userWeb.documentNumber;
  }, [DNI]);

  useEffect(() => {
    // Agregar un event listener cuando el componente se monta
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Eliminar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    window.innerWidth < 451 ? setBtnText("Validar identidad") : null;
  }, [window.innerWidth]);

  const handleBeforeUnload = async (e) => {
    const message = "¿Seguro que quieres salir?";
    e.returnValue = message;
    const userResponse = window.confirm(message);
    try {
      var BodyPayload = {
        step: 3,
        documentNumber: userWeb.documentNumber,
        documentType: "00",
        active: true,
      };
      var abandonRes = await SendFetch(Abandon(BodyPayload));
    } catch (error) {
      console.error("error", error);
      // Maneja el error de manera adecuada
    }
  };

  const getDeviceInfo = () => {
    // Tipo de dispositivo
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    const deviceType = isMobile ? "Mobile" : "Desktop";
    let mobileOS = "Unknown";
    if (isMobile) {
      if (/Android/i.test(navigator.userAgent)) {
        mobileOS = "Android";
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        mobileOS = "iOS";
      }
    }
    // Sistema operativo
    const os = navigator.platform;
    // Navegador
    const browser = (() => {
      const userAgent = navigator.userAgent;
      const browsers = {
        Chrome: /Chrome/i,
        Firefox: /Firefox/i,
        Edge: /Edg/i,
        Safari: /Safari/i,
        Opera: /Opera|OPR/i,
        IE: /Trident|MSIE/i,
      };
      for (const key in browsers) {
        if (browsers[key].test(userAgent)) {
          return key;
        }
      }
      return "Unknown";
    })();
    var concatVal = deviceType + "|" + mobileOS + "|" + os + "|" + browser;
    setValuesDevice(concatVal);
    setUserAgentValue(navigator.userAgent);
    if (isMobile) {
      var OS = mobileOS.toLowerCase();
      dispatch({ type: "FIELD", payload: { field: "device", value: OS } });
    } else {
      dispatch({
        type: "FIELD",
        payload: { field: "device", value: "desktop" },
      });
    }
    dispatch({
      type: "FIELD",
      payload: { field: "userAgent", value: navigator.userAgent },
    });
    dispatch({
      type: "FIELD",
      payload: { field: "deviceInfo", value: concatVal },
    });
  };

  const backButton = () => {
    dispatch({
      type: "BACK_BIOMETRIC_STEP",
    });
    updateUrl(history, window.location.href, "/pasos2");
  };

  const getValue = (str, currentURL) => {
    const startIndex = currentURL.indexOf(str);
    if (startIndex !== -1) {
      // Encontrar la posición de "&" después de "utm_source="
      const endIndex = currentURL.indexOf("&", startIndex);
      // Extraer la subcadena entre "utm_source=" y "&"
      const strValue =
        endIndex !== -1
          ? currentURL.substring(startIndex + str.length, endIndex)
          : currentURL.substring(startIndex + str.length);
      return strValue.substring(1);
    } else {
      return "not set";
    }
  };

  const startValidation = async () => {
    try {
      SetLoading(true);
      var body = {
        documentType: userWeb.documentType,
        documentNumber: userWeb.documentNumber,
      };
      SendFetch(SavePhotoReco(body)).then((res) => {
        if (res.code == 1) {
          getDeviceInfo();
          dispatch({
            type: "FIELD",
            payload: { field: "stepCurrent", value: 3.5 },
          });
          dispatch({
            type: "FIELD",
            payload: { field: "showDivBenefits", value: false },
          });
          const currentUrl = window.location.href;
          const utm_source = getValue("utm_source", currentUrl);
          const utm_medium = getValue("utm_medium", currentUrl);
          const utm_campaign = getValue("utm_campaign", currentUrl);
          var payload = {
            section: "paso 3",
            dni: userWeb.documentNumber,
            option: "validar identidad",
            variante: "tipo_c",
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            edad: userWeb.age,
          };
          payload.proceso = "automatico";
          sendToData(currentUrl, "button_click", "button_click", payload);
          updateUrl(history, currentUrl, "/pasos3validacion-biometrica");
        }
      });
    } catch (e) {
      console.log("error", e);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setHelpCall(false);
    setModalOpen(false);
  };

  const sendEmail = async () => {
    const currentUrl = window.location.href;
    let body = {
      indicator: 2,
      parameters: {
        documentType: "00",
        documentNumber: userWeb.documentNumber,
      },
    };
    await SendFetch(helpMail(body));

    let payload = {
      section: "paso 3",
      message: "contacta con un asesor",
      option: "solicitar llamada",
      dni: userWeb.documentNumber
    };
    sendToData(currentUrl, "button_click", "button_click", payload);
    setHelpCall(true);
  };
  const openModal = () => {
    const currentUrl = window.location.href;
    let payload = {
      section: "paso 3",
      option: "problemas para continuar",
    };
    sendToData(currentUrl, "button_click", "button_click", payload);
    setModalOpen(true);
  };
  const returnPrevious = () => {
    const currentUrl = window.location.href;
    dispatch({
      type: "BACK_DOCUMENTATION_STEP",
    });
    dispatch({
      type: "FIELD",
      payload: { field: "biometricVerified", value: false },
    });
    dispatch({
      type: "FIELD",
      payload: { field: "imageBiometric", value: null },
    });
    dispatch({
      type: "FIELD_SECOND",
      payload: { field1: "userWeb", field2: "biometric", value: null },
    });
    window.location.href.includes("asesor")
      ? updateUrl(history, currentUrl, "/asesor/pasos3")
      : updateUrl(history, currentUrl, "/pasos3");
  };

  return (
    <Step3Wrapper>
      <Carousel startValidation={startValidation} />
      {!helpCall && (
        <TraspasoModal isOpen={modalOpen} onClose={closeModal}>
          <div className="modal-content-inner">
            <img
              src={asistenteVirtual}
              alt="modal_image"
              className="modal-image"
            />
            <h2 className="modal-title">Descuida, te ayudamos</h2>
            <p className="modal-description">
              Solicita una llamada de nuestra asesora que te ayudará a terminar
              tu traspaso.
            </p>
            <button onClick={sendEmail} className="modal-button">
              Solicitar llamada
            </button>
          </div>
        </TraspasoModal>
      )}
      {helpCall && (
        <TraspasoModal isOpen={modalOpen} btnClose={false}>
          <div className="modal-content-inner">
            <img src={imageLike} alt="modal_image" className="modal-image" />
            <h2 className="modal-title">Listo</h2>
            <p className="modal-description">
              Nuestra asesora se comunicará contigo en unos momentos.
            </p>
            <button onClick={returnPrevious} className="modal-button">
              Entendido
            </button>
          </div>
        </TraspasoModal>
      )}
      <ButtonsContainer id="btnStep3" className="wrapperButton">
        <Button
          id="start-biometric"
          data-testid="input-field"
          onClick={startValidation}
          className="newUiBtnBiometric"
        >
          {btnText}
        </Button>

        {window.innerWidth < 451 && (
          <DivBackButton className={"newUiBtn"} onClick={backButton}>
            {" "}
            Regresar{" "}
          </DivBackButton>
        )}
      </ButtonsContainer>
      <div className="helpSection" onClick={openModal}>
        <div className="linkHelp">¿No puedes validarte ahora?</div>
      </div>
    </Step3Wrapper>
  );
};
