import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import background from "../../components/Banner/Images-sin-asesor/StepOne/background_desktop.jpg";
import backgroundMov from "../../components/Banner/Images-sin-asesor/StepOne/background_mobile.jpg";
import backgroundTab from "../../components/Banner/Images-sin-asesor/StepOne/background_tablet.jpg";
import backgroundStep2 from "../../components/Banner/Images-sin-asesor/StepTwo/background_desktop.jpg";
import backgroundMovStep2 from "../../components/Banner/Images-sin-asesor/StepTwo/background_mobile.jpg";
import backgroundTabStep2 from "../../components/Banner/Images-sin-asesor/StepTwo/background_tablet.jpg";
import backgroundStep3 from "../../components/Banner/Images-sin-asesor/StepThree/background_desktop.jpg";
import backgroundMovStep3 from "../../components/Banner/Images-sin-asesor/StepThree/background_mobile.jpg";
import backgroundTabStep3 from "../../components/Banner/Images-sin-asesor/StepThree/background_tablet.jpg";
import backgroundStep4 from "../../components/Banner/Images-sin-asesor/StepFour/background_desktop.jpg";
import backgroundMovStep4 from "../../components/Banner/Images-sin-asesor/StepFour/background_mobile.jpg";
import backgroundTabStep4 from "../../components/Banner/Images-sin-asesor/StepFour/background_tablet.jpg";
import { useStateDigitalTransfersContext } from "../../../digital-transfers/context/DigitalTransfersContext";
import logoPrima from "../../../digital-transfers/pages/Resources_stepInitial/logoPrima.png";
import logoPrima2 from "../../assets/images/logoPrima2.svg";
import {
  Wrapper,
  ContentStepInitial,
  DivBenefits,
  DivPage,
  TextStyledMobile,
  DivHeader,
  TextStyledStep0,
  DivContent,
  DivStep,
} from "./stylesDT";
let user = null;

import infoCard1 from "../../../App/assets/images/infocard1.svg";
import infoCard2 from "../../../App/assets/images/infocard2.svg";
export const UserContext = React.createContext(user);

const PublicPagesLayout = ({ children }) => {
  const contentRef = useRef(null);
  const { showDivBenefits } = useStateDigitalTransfersContext();
  const [fondo, setFondo] = useState(background);
  const { stepCurrent } = useStateDigitalTransfersContext();
  const ref = useRef();
  useEffect(() => {
    switch (stepCurrent) {
      case 1:
        if (window.innerWidth > 769) setFondo(background);
        if (window.innerWidth <= 461) setFondo(backgroundMov);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTab);
        break;
      case 2:
        if (window.innerWidth > 769) setFondo(backgroundStep2);
        if (window.innerWidth <= 461) setFondo(backgroundMovStep2);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTabStep2);
        break;
      case 3:
        if (window.innerWidth > 769) setFondo(backgroundStep3);
        if (window.innerWidth <= 461) setFondo(backgroundMovStep3);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTabStep3);
        break;
      case 3.5:
        if (window.innerWidth > 769) setFondo(backgroundStep3);
        if (window.innerWidth <= 461) setFondo(backgroundMovStep3);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTabStep3);
        break;
      case 4:
        if (window.innerWidth > 769) setFondo(backgroundStep4);
        if (window.innerWidth <= 461) setFondo(backgroundMovStep4);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTabStep4);
        break;
      case 5:
        if (window.innerWidth > 769) setFondo(backgroundStep4);
        if (window.innerWidth <= 461) setFondo(backgroundMovStep4);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTabStep4);
        break;
      default:
        if (window.innerWidth > 769) setFondo(background);
        if (window.innerWidth <= 461) setFondo(backgroundMov);
        if (window.innerWidth > 461 && window.innerWidth <= 769)
          setFondo(backgroundTab);
    }
  }, [stepCurrent]);
  return (
    <Wrapper>
      <ContentStepInitial
        ref={contentRef}
        showDivBenefits={showDivBenefits}
        id="app-main-content-steps"
      >
        <DivBenefits
          className="benefitImg"
          id="idBenefitImg"
          data-testid="idBenefitImg"
          style={{
            backgroundImage: "url(" + fondo + ")",
            mixBlendMode: "normal",
            backgroundSize: "cover",
            backgroundBlendMode: "multiply",
            flexDirection: "column",
            flexWrap: "nowrap",
            justifyContent: "flex-end",
            left: "0px",
            top: "72px",
            backgroundPosition: "center top",
          }}
        >
          <div
            className="headerMobile_1"
            style={{
              marginTop: "27px",
              marginLeft: "24px",
              marginRight: "24px",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={logoPrima2}
                style={{ width: "94px", height: "17.84px" }}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <TextStyledMobile>Ayer, Hoy, Siempre</TextStyledMobile>
              </div>
            </div>
          </div>

          {/* <div className="headerLogo">
            <div style={{ flex: 1, display: "flex" }}>
              <img
                src={logoPrima2}
                style={{ width: "94px", height: "17.84px" }}
                alt=""
              />
            </div>
          </div> */}

        </DivBenefits>

        <DivPage id="idDivPage">
          <DivStep id="idContentStep" className={window.location.href.includes('finalizacion-solicitud') || window.location.href.includes('pasos3validacion-biometrica')? 'finishPageCs':''}>
            <DivContent id="idDivContentStep">{children}</DivContent>
          </DivStep>
        </DivPage>

        <DivHeader id="idHeaderMobile" style={{ display: "none" }} ref={ref}>
          <div style={{ flex: 1, display: "flex" }}>
            <img
              src={logoPrima}
              style={{ width: "94px", height: "17.84px" }}
              alt=""
            />
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <TextStyledStep0>Ayer, Hoy, Siempre</TextStyledStep0>
          </div>
        </DivHeader>
      </ContentStepInitial>
    </Wrapper>
  );
};

PublicPagesLayout.propTypes = {
  children: PropTypes.any,
};

export default PublicPagesLayout;
