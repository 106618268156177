import React, { useState, useEffect } from 'react';
import reco1 from '../../components/SelfieRecomendations/reco1.gif';
import reco2 from '../../components/SelfieRecomendations/reco2.gif';
import reco3 from '../../components/SelfieRecomendations/reco3.gif';
import { updateUrl } from "../../constants/updateUrl";
import { useHistory } from 'react-router-dom';
import { CarouselWrapper } from "./style";

const items = [
    { id: 1, img: reco1, title: 'Busca un lugar', subtitle: 'iluminado' },
    { id: 2, img: reco2, title: 'Mantente', subtitle: 'centrado' },
    { id: 3, img: reco3, title: 'No te muevas', subtitle: '' }
];

export default function Carousel  ({startValidation}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [countdown, setCountdown] = useState(10);
    const history = useHistory();

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000);

        if (countdown === 0) {
            startValidation();
        }

        return () => clearInterval(countdownInterval);
    }, [countdown]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
        }, 3000);

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return (
        <CarouselWrapper>
            <div className='ContentCarousel'>
                {
                    countdown >-1 &&  <div className='counterDiv'>
                        <span className='textA' style={{color:'#4E5258'}}>¡Prepárate! iniciamos en</span> <p className='textB' style={{color:'#00A499'}}> {countdown} segundos </p> 
                    </div>
                }

                <img className='imgCarousel' src={items[currentIndex].img}></img>
                <div className='recoCarousel'>
                    <span className='textA' style={{color:'#4E5258'}}>{items[currentIndex].title}</span>
                    <span className='textB'  style={{color:'#00A499'}}>{items[currentIndex].subtitle}</span>
                </div>
            </div>
            <div className='circlesCarousel' style={{ marginTop: '10px' }}>
                {items.map((item, index) => (
                    <span
                        key={item.id}
                        style={{
                            display: 'inline-block',
                            width:  currentIndex === index ? '12px' : '8px',
                            height:  currentIndex === index ? '12px' : '8px',
                            borderRadius: '50%',
                            backgroundColor: currentIndex === index ? 'rgba(4, 166, 162, 1)' : 'rgba(209, 210, 211, 1)',
                            margin: '0 5px'
                        }}
                    ></span>
                ))}
            </div>
        </CarouselWrapper>
    );
}