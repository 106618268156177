import { useStateDigitalTransfersContext } from "../../context/DigitalTransfersContext";
import React, { useEffect } from "react";
import { StepContactData } from "../StepContactData";
import { StepFundType } from "../StepFundType";
import { StepRecognition } from "../StepsRecognition";

export const StepsContent = ({ buttonRef }) => {
  const { stepCurrent } = useStateDigitalTransfersContext();
  let originalTitle = document.title;
  let attentionTitle = ["¡Un momento!", "Aún no formas parte de la mejor AFP."];
  let attentionIndex = 0;
  let isFlashing = false;

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Borrar el item de sessionStorage
      sessionStorage.clear();
      // Refrescar la página
      window.location.reload();
    }, 1200000); // 20 minutos ms

    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, []);

  function startFlashing() {
      if (!isFlashing) return;
      document.title = attentionTitle[attentionIndex];
      attentionIndex = (attentionIndex + 1) % attentionTitle.length;
      setTimeout(startFlashing, 1000);
  }
  
  useEffect(() => {
    if (typeof document !== 'undefined') {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          isFlashing = true;
          startFlashing();
        } else {
          isFlashing = false;
          document.title = originalTitle;
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Limpia el evento cuando el componente se desmonte
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }
  }, []);

  if (stepCurrent === 1) {
    const linkRemove = document.querySelector('link[rel="canonical"][href="https://traspaso.prima.com.pe/"]');
    console.log(linkRemove)
    if (linkRemove) {
      document.head.removeChild(linkRemove);
    }
    const linksAdd = [
      { rel: "next", href: "https://traspaso.prima.com.pe/pasos1" },
      { rel: "canonical", href: "https://traspaso.prima.com.pe/pasos0" },
    ];
    linksAdd.forEach((link) => {
      if (!document.querySelector(`head link[href="${link.href}"]`)) {
        const newLink = document.createElement("link");
        newLink.rel = link.rel;
        newLink.href = link.href;
        document.head.appendChild(newLink);
      }
    });
    return (
      <div>
        <StepContactData />
      </div>
    );
  }

  if (stepCurrent === 2 && window.location.href.includes("pasos2")) {
    
    const linkRemove = document.querySelector('link[rel="prev"][href="https://traspaso.prima.com.pe/pasos0"]');
    const linksRemove1 = document.querySelector('link[rel="next"][href="https://traspaso.prima.com.pe/pasos2"]');
    if (linkRemove) {
      document.head.removeChild(linkRemove);
    }
    if (linksRemove1) {
      document.head.removeChild(linksRemove1);
    }
    const linksAdd = [
      { rel: "prev", href: "https://traspaso.prima.com.pe/pasos1" },
      { rel: "next", href: "https://traspaso.prima.com.pe/pasos3" },
    ];
    linksAdd.forEach((link) => {
      if (!document.querySelector(`head link[href="${link.href}"]`)) {
        const newLink = document.createElement("link");
        newLink.rel = link.rel;
        newLink.href = link.href;
        document.head.appendChild(newLink);
      }
    });
    return (
      <div>
        <StepFundType />
      </div>
    );
  }

  if (stepCurrent === 3 && window.location.href.includes("pasos3")) {
    const linkRemove = document.querySelector('link[rel="prev"][href="https://traspaso.prima.com.pe/pasos1"]');
    const linksRemove1 = document.querySelector('link[rel="next"][href="https://traspaso.prima.com.pe/pasos3"]');
    if (linkRemove) {
      document.head.removeChild(linkRemove);
    }
    if (linksRemove1) {
      document.head.removeChild(linksRemove1);
    }
    const linksAdd = [
      { rel: "prev", href: "https://traspaso.prima.com.pe/pasos2" },
      { rel: "next", href: "https://traspaso.prima.com.pe/finalizacion-solicitud" },
    ];
    linksAdd.forEach((link) => {
      if (!document.querySelector(`head link[href="${link.href}"]`)) {
        const newLink = document.createElement("link");
        newLink.rel = link.rel;
        newLink.href = link.href;
        document.head.appendChild(newLink);
      }
    });
    return (
      <div>
        <StepRecognition />
      </div>
    );
  }

  return <div></div>;
};
