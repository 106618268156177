import styled from "styled-components";
import { Typography } from "@prima-afp/prima-ui-elements/dist/core";

export const TypographyStyled = styled(Typography)`
    font-family: "Calibri";
    color: #6E6E6E;
    font-weight: 400;
    font-size: 16px; 
    line-height: 20px;

    ${props => props.font && "font-family:" + props.font};
    ${props => props.weight && "font-weight:" + props.weight};
    ${props => props.size && "font-size:" + props.size + "px"};
    ${props => props.color && "color:" + props.color};
    ${props => props.line && "line-height:" + props.line + "px"};

    a {
        text-decoration: underline;
    }

    .orange {
        color: var(--orangeColorMain);
    }

    h3 {
		font-size: 20px;
		line-height: 24px;
		font-weight: 600;
	}

	.bold {
		/* font-size: 20px; */
		font-weight: 600;
	}
`;