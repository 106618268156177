import React from "react";
import { ModalContainer } from "./styles";
import  iconClose from "../../assets/images/Cerrar.svg"

export function TraspasoModal({ btnClose = true, isOpen, onClose, children }) {
  const closeModal = () => {
    onClose();
  };
  return (
    <ModalContainer>
      {isOpen && (
        <div className="modal-overlay" data-testid="modal-overlay">
          <div className="modal-content">
            { 
              btnClose && <div className="headerModal">
                  <button className="close-modal" onClick={closeModal} data-testid="close-button">
                  <img src={iconClose} />
                  </button>
              </div>
            }
            <div className="bodyModal">
                {children}

            </div>
          </div>
        </div>
      )}
    </ModalContainer>
  );
}