import ServiceFetcher from "../../shared/libs/ServiceFetcher";

export const AcceptTermsConditions = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/accept-terms-conditions`, {
    method: "POST",
    body,
});

export const Abandon = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/abandon`, {
    method: "POST",
    body,
});

export const SavePhotoReco = ( body ) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/save-photo-recommendation`, {
    method: "POST",
    body
});

export const BiometricValidation = (body, documentType, documentNumber, phoneNumber, email, nameUser, byAssessor ) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/biometric-validation?documentType=${documentType}&documentNumber=${documentNumber}&phoneNumber=${phoneNumber}&email=${email}&name=${nameUser}&isSoliciting=false&byAssessor=${byAssessor}`, {
    method: "POST",
    body,
});

export const CreateTransfer = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/create-transfer`, {
    method: "POST",
    body,
});

export const SendDocumentIdentity = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/validate-document`, {
    method: "POST",
    body,
});

export const SendGetMain = () => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/`, {
    method: "GET",
});

export const SendGetParameters = (tipo) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital?type=${tipo}`, {
    method: "GET",
});

export const SendGetCompanies = (razonSocial, nDocumento) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/employers?documentNumber=${nDocumento}&filter=${razonSocial}`, {
    method: "GET",
});

export const ReturnProcess = (documentNumber, token) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/return-process?dn=${documentNumber}&t=${token}`, {
    method: "GET",
});

export const SendSaveEmployer = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/save-employer`, {
    method: "POST",
    body
});

export const SendSaveFundType = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/save-fund-type`, {
    method: "POST",
    body
});

export const SendCodeVerification = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/validate-sms`, {
    method: "POST",
    body
});

export const ReSendCodeVerification = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/resend-sms`, {
    method: "POST",
    body
});

export const UploadPhotoWidget = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/upload-widget`, {
    method: "POST",
    body
});

export const UpdateAddress = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/update-address`, {
    method: "POST",
    body
});

//lists
export const SendGetSectors = () => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_GENERIC_DATA}/generic-data/list-locals/`, {
    method: "GET",
});

export const SendGetVias = () => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_GENERIC_DATA}/generic-data/list-vias/`, {
    method: "GET",
});

export const SendGetDepartments = () => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_GENERIC_DATA}/generic-data/list-states/`, {
    method: "GET",
});

export const SendGetProvinces = (departmentId) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_GENERIC_DATA}/generic-data/list-provinces/${departmentId}`, {
    method: "GET",
});

export const SendGetDistricts = (departmentId, provinceId) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_GENERIC_DATA}/generic-data/list-districs/${departmentId}/${provinceId}`, {
    method: "GET",
});

export const SendQueryIdentity = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/request/check`, {
    method: "POST",
    body
});

export const SendQueryFormIdentity = (docid, sechash, t) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/status/process${encodeURIComponent(`?docid=${docid}&t=${t}&sechash=${sechash}`)}`, {
    method: "GET",
});

export const validateReniec = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/validate-reniec-service`, {
    method: "GET",
});

export const helpMail = (body) => ServiceFetcher(`${process.env.REACT_APP_API_URL_PUBLIC_WEB_TRANSFER_DIGITAL}/transfers-digital/notification/send-email`, {
    method: "POST",
    body
});