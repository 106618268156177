import styled from "styled-components";
import { Typography } from "@prima-afp/prima-ui-elements/dist/core";

export const TypographyStyled = styled(Typography)`
    font-family: "Calibri";
    color: #6E6E6E;
    font-weight: 400;
    font-size: 16px;

    ${props => props.font && "font-family:" + props.font};
    ${props => props.weight && "font-weight:" + props.weight};
    ${props => props.size && "font-size:" + props.size + "px"};
    ${props => props.color && "color:" + props.color};

    a {
        text-decoration: underline;
    }

    .orange {
        color: var(--orangeColorMain);
    }
`;