import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatchDigitalTransfersContext } from "../../../digital-transfers/context/DigitalTransfersContext";
import { Link } from 'react-router-dom';
import { sendToData } from "../../../digital-transfers/constants/sendToDataLayer";
import { ModalErrorContent } from "./styles";
import  iconClose from "../../assets/images/Cerrar.svg";
import {
    Modal as BaseModal,
    Typography,
    Text,
    Button,
} from "@prima-afp/prima-ui-elements/dist/core";

import { Icon, HeaderModal } from "./styles";

import alertIcon from "../../assets/images/alert.svg";
import happyIcon from "../../assets/images/happy.svg";
import haveRequestIcon from "../../assets/images/haveRequestIcon.svg";
import approvedIcon from "../../assets/images/aprobed.svg";
import asistenteVirtual from "../../../digital-transfers/assets/images/asistenteVirtual.svg";
import Bowser from 'bowser';
import platform from 'platform';
 
export function ErrorModalNewUI(props) {

    const { type, isActive, title, content, textButton, returnStep, idModal, showX, className } = props.settings;
    const [frameIcon, setFrameIcon] = useState(<></>);
    const [frameButton, setFrameButton] = useState(<></>);
    const dispatch = useDispatchDigitalTransfersContext();

    const getOperatingSystem = () => {
        // Obtener información sobre el sistema operativo
        const os = platform.os;
        return `${os.family} ${os.version}`;
    };
    const getBrowserInfo = () => {
        const browserInfo = Bowser.getParser(window.navigator.userAgent);
        return {
            name: browserInfo.getBrowserName(),
            version: browserInfo.getBrowserVersion(),
        };
    };
    const getDataDevice = () => {
        // Obtenemos información del dispositivo y del navegador
        const os = getOperatingSystem();
        const browserInfo = getBrowserInfo();    
        const deviceInfo =  os + "|" + browserInfo.name + "|" + browserInfo.version;
        return deviceInfo.toLowerCase();
    };
    const deviceInfo = getDataDevice();

    
    const returnStep0 = (idModal) => {
        switch (idModal) {
            case "btn-step3-attempts-biometric":
            var currentUrl = window.location.href;
            var payload = {
                section: ("paso 3"),
                message: ("aviso has superado la cantidad de validaciones por biometria"),
                option: ("entendido"),
            };
            sendToData(currentUrl, "button_click", "button_click", payload);  
            break;
            case "btn-address-success":
                var payload5 = {
                    section: ("popup direccion actualizada"),
                    option: ("entendido"),
                };
                sendToData(window.location.href, "button_click", "button_click", payload5);  
            break;
        }
        dispatch({ type: 'INITIAL_STATE', });
    };

    const genericCloseModal = async (idModal) => {
        switch (idModal) {
            case "btn-step3-error-validation":
            var currentUrl = window.location.href;
            var payload = {
                section: ("paso 3"),
                message: ("aviso lo sentimos, estamos trabajando para solucionar el incoveniente"),
                option: ("cerrar"),
            };
            sendToData(currentUrl, "button_click", "button_click", payload);  
            break;
            case "btn-onp-affiliate":
            var currentUrl2 = window.location.href;
            var payload2 = {
                section: ("validacion_onp"),
                message: ("usuario afiliado a ONP"),
                option: ("entendido"),
            };
            sendToData(currentUrl2, "button_click", "button_click", payload2);  
            break;
            case "btn-prima-client":
            var currentUrl3 = window.location.href;
            var payload3 = {
                section: ("paso 1"),
                message: ("ya es cliente"),
                option: ("entendido"),
            };
            sendToData(currentUrl3, "button_click", "button_click", payload3);  
            break;
            case "btn-have-request":
                var currentUrl4 = window.location.href;
                var payload4 = {
                    section: ("paso 1"),
                    message: ("tiene solicitud"),
                    option: ("entendido"),
                };
                sendToData(currentUrl4, "button_click", "button_click", payload4);  
            break;
            case "btn-no-camera":
                var currentUrl5 = window.location.href;
                var payload5 = {
                    section: ("paso 1"),
                    message: ("Advertencia cámara bloqueada"),
                    option: ("entendido"),
                };
                sendToData(currentUrl5, "button_click", "button_click", payload5);  
            break;
        }
        dispatch({
            type: "CLOSE_MODAL",
        });
    };

    React.useEffect(() => {
        switch (type) {
            case "alert":
                setFrameIcon(<div style={{marginBottom: '24px'}}>
                    <Icon className='alert' src={alertIcon} alt="modal-alert" />
                </div>);
                break;
            case 'haveRequest':
                setFrameIcon(<div style={{marginBottom: '24px'}}>
                    <Icon src={haveRequestIcon} id="idHaveRequest" alt="modal-alert" />
                </div>);
                break;
            case "happy":
                setFrameIcon(<div style={{marginBottom: '24px'}}>
                    <Icon src={happyIcon} alt="modal-alert" />
                </div>);
                break;

            case "approved":
                setFrameIcon(<div style={{marginBottom: '24px'}}>
                    <Icon src={approvedIcon} alt="modal-approved" />
                </div>);
                break;
            case "restartCardSelection":
                setFrameIcon(<div style={{marginBottom: '24px'}}>
                    <Icon src={asistenteVirtual} alt="modal-card" />
                </div>);
                break;
 
            default: setFrameIcon(<></>);
        }
    }, [type]);

    React.useEffect(() => {
        switch (returnStep) {
            case "STEP_0":    
                setFrameButton(<Link to="/pasos0"> <Button id={idModal} size={"medium"} className='newUiBtn' onClick={() => returnStep0(idModal)}>{textButton || 'Cerrar'}</Button> </Link> );
                break;

            case "BACK_MAXIMUN_ATTEMPTS":
                setFrameButton( <Button id={idModal} className='newUiBtn' onClick={() =>
                    dispatch({
                        type: 'BACK_MAXIMUN_ATTEMPTS'
                    })} size={"medium"}>
                    {textButton || 'Cerrar'}
                    
                </Button>);
                break;

            default: setFrameButton(<Button id={idModal} className='newUiBtn' onClick={() => genericCloseModal(idModal)}  size={"medium"}>{textButton || 'Cerrar'} </Button> );
        }
    }, [returnStep]);

    return (
        <BaseModal show={isActive}>
            { showX && <HeaderModal>
                  <button className="close-modal" onClick={() => genericCloseModal(idModal)}>
                  <img src={iconClose} />
                  </button>
              </HeaderModal>
            }
            <ModalErrorContent style={{textAlign: 'center'}}>
                {frameIcon}
                <div>
                    <Typography className="title">{title}</Typography>
                </div>
                <div style={{marginBottom: '32px'}}>
                    <div className={`content ${className} centerContent`} dangerouslySetInnerHTML={{__html: content}}></div>
                </div>
                {frameButton}

            </ModalErrorContent>
        </BaseModal>
    );
}

ErrorModalNewUI.propTypes = {
    closeModal: PropTypes.any,
    settings: PropTypes.shape({
        content: PropTypes.any,
        isActive: PropTypes.any,
        title: PropTypes.any,
        type: PropTypes.string,
        idModal: PropTypes.string,
        textButton: PropTypes.string,
        returnStep: PropTypes.any,
        showX: PropTypes.any,
    })
};
