import React from "react";
import { FirstBannerContainer, GradientOverlay } from "./style";
import TestimonialSlider from "../../../../components/slider/testimonial/testimonialSlider";
import { redirectURL } from "../../../../constants/functions";

const FirstBanner = () => {
  return (
    <FirstBannerContainer>
      <div className="contents">
        <div className="contentText">
          <h1>Cámbiate a Prima AFP</h1>
          <div className="subTitle">
            Haz tu solicitud fácil, rápido y totalmente online
          </div>
          <div className="btnsChange">
            <button
              className="btnSlide"
              onClick={() =>
                redirectURL("traspaso", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "cambiate ya",
                    position_banner: "banner_1",
                  },
                })
              }
            >
              Cámbiate ya
            </button>
            <button
              className="btnSlideSecundary"
              onClick={() =>
                redirectURL("traspasoAsesor", {
                  event: "button_click",
                  payload: {
                    section: "landing traspaso",
                    sub_section: "banner",
                    option: "me estan asesorando",
                    position_banner: "banner_1",
                  },
                })
              }
            >
              Me está ayudando un asesor
            </button>
          </div>
        </div>
        <div className="contentComents">
          <TestimonialSlider />
        </div>
      </div>
      <GradientOverlay />
    </FirstBannerContainer>
  );
};

export default FirstBanner;
