import React, { useState, useRef, useEffect } from "react";
import step1 from "./step1.svg";
import step2 from "./step2.svg";
import step3 from "./step3.svg";
import step4 from "./step4.svg";
import { WrapperStepper, TitleMobile, HeadeMobile } from "./styles";
import useIsMobile from "modules/digital-transfers/hooks/useMobile";

export default function Stepper({ stepList = [], currentStep }) {
  const [title, setTitle] = useState("Cambio");
  const [subtitle, setSubtitle] = useState("subt");
  const [image, setImage] = useState(step1);
  const isMobile = useIsMobile();
  useEffect(() => {
    const step = stepList.find((element) => {
      return element.id === currentStep;
    });
    step ? setTitle(step.title) : null;
    step ? setSubtitle(step.subtitle) : null;
    switch (currentStep) {
      case 0:
        setImage(step1);
        break;
      case 1:
        setImage(step1);
        break;
      case 2:
        setImage(step2);
        break;
      case 3:
        setImage(step3);
        break;
      case 4:
        setImage(step4);
        break;
    }

    if (currentStep == 1) {
      document.title =
        "Comienza aquí tu cambio de AFP | es fácil, gratis y 100% digital | Prima AFP";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Cambiar de AFP nunca fue tan facil. Integrate ya a Prima AFP. Disfruta de la seguridad y el respaldo del grupo Credicorp"
        );
        const metaRobots = document.querySelector('meta[name="robots"]');
        if (metaRobots) {
          metaRobots.parentNode.removeChild(metaRobots);
        }
    } else {
      document.title = "Cambiar de AFP | Traspaso Online | Prima AFP";
      document
        .querySelector('meta[name="description"]')
        .setAttribute(
          "content",
          "Cambiar de AFP Online en pocos pasos. Con Prima AFP puede trasladar su fondo de pensión con la mejor rentabilidad ¡Cambiar de AFP ahora!"
        );
      const metaRobots = document.querySelector('meta[name="robots"]');
      if (!metaRobots) {
        const meta = document.createElement("meta");
        meta.name = "robots";
        meta.content = "noindex, nofollow";
        document.head.appendChild(meta);
      } else {
        metaRobots.setAttribute("content", "noindex, nofollow");
      }
    }
  }, [currentStep]);

  useEffect(() => {}, []);

  return (
    <>
      {currentStep == 1 && isMobile && (
        <HeadeMobile>
          <p className="titleMobile">
            Cámbiate a <span className="itemOrange">Prima AFP</span>
          </p>
          <p className="subTitleMobile">Es fácil, rápido y totalmente online</p>
        </HeadeMobile>
      )}
      <WrapperStepper>
        <img src={image} className="imgStepper" />
        <div className="wrapperContent">
          <div className="titleStepper">{title}</div>
          <div className="subtitleStepper">{subtitle}</div>
        </div>
      </WrapperStepper>
    </>
  );
}
